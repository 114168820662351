.library {
    .library-wrapper {
        padding: 5px 15px 90px;

        .chips {
            position: sticky;
            top: 70px;
            z-index: 5;
            background-color: #010A19;
            padding: 10px 0 20px;
        }

        .contents {
            .cards {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .wrapper-item {
                    padding-bottom: 40px;
                }
            }
        }
    }
}

// XS
@media only screen and (max-width: 575px) {
    .library {
        .library-wrapper {
            padding-bottom: 136px;

            .cards {
                justify-content: space-between;

                .wrapper-item {
                    .item {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}