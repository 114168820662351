.playlist-item {
    width: 20%;

    .playlist-item-wrapper {
        display: flex;
        padding-right: 20px;
        padding-bottom: 40px;

        .playlist-item-left {
            display: flex;
            flex-direction: column;
            margin-right: 5px;
            cursor: pointer;

            &:hover {
                .playlist-img {
                    img {
                        scale: 1.1;
                    }
                }
            }

            .playlist-img {
                width: 100%;
                aspect-ratio: 1;
                border-radius: 10px;
                overflow: hidden;
                margin-bottom: 12px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: scale .2s ease;
                }
            }

            .playlist-info {
                .name {
                    font-weight: 500;
                    font-size: 16px;
                    margin-bottom: 5px;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .type {
                    font-weight: 500;
                    font-size: 13px;
                    color: #cccccc;
                }
            }
        }

        .playlist-item-right {

            .icon {
                cursor: pointer;
                color: #969696;
                transition: color .1s ease;

                &:hover {
                    color: white;
                }
            }
        }
    }
}

// XS
@media only screen and (max-width: 575px) {
    .playlist-item {
        width: 50%;
    }
}

// SM && MD
@media only screen and (min-width: 576px) and (max-width: 992px) {
    .playlist-item {
        width: 33.33%;
    }
}

// SM
@media only screen and (min-width: 576px) and (max-width: 768px) {}

// MD
@media only screen and (min-width: 769px) and (max-width: 992px) {}

// LG
@media only screen and (min-width: 993px) and (max-width: 1200px) {}

// XL
@media only screen and (min-width: 1201px) and (max-width: 1400px) {}

// XXL
@media only screen and (min-width: 1401px) {}