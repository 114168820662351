.song-table {
    padding: 10px;

    .title {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 13px;
        font-weight: 500;
        color: #cccccc;
        padding: 0 5px 15px 5px;
        border-bottom: 1px solid #96969650;
        margin-bottom: 15px;

        .number {
            font-size: 1.2em;
        }
    }

    .number {
        flex: 0.5;
        text-align: center;
        margin-right: 10px;
    }

    .song-info {
        margin-right: 30px;

        &.playlist {
            flex: 4;
        }

        &.album {
            flex: 5.5;
        }
    }

    .song-plays {
        flex: 4;
        text-align: right;
        margin-right: 40px;
    }

    .song-album {
        flex: 3.5;
        margin-right: 30px;
    }

    .song-createdAt {
        flex: 2;
        display: flex;
        justify-content: flex-start;
    }

    .button {
        flex: 0.5;
        min-width: 0;
    }

    .song-duration {
        flex: 1;
        text-align: center;
    }

    .collapse-button {
        margin: 10px 0 0;

        .text {
            font-size: 13px;
            font-weight: 500;
            color: #cccccc;
            transition: all .1s ease;
            padding: 0 10px;
            cursor: default;
        }
    }
}

// Thiet bi khong phai cam ung
@media (hover: hover) and (pointer: fine) {
    .song-table {
        .collapse-button {
            .text {
                &:hover {
                    color: white;
                }
            }
        }
    }
}

// XS
@media only screen and (max-width: 575px) {
    .song-table {
        padding: 0;

        .button {
            flex: 1;
        }

        .title {
            display: none;
        }

        .number,
        .song-album,
        .song-createdAt,
        .song-duration {
            display: none;
        }

        .song-plays {
            flex: 2;
        }

        &.artist {
            .button {

                &.liked {
                    display: none;
                }
            }
        }

        &.album {
            .song-plays {
                display: none;
            }
        }
    }
}

// SM & MD
@media only screen and (min-width: 576px) and (max-width: 992px) {
    .song-table {
        .song-createdAt {
            display: none;
        }
    }
}

// SM
@media only screen and (min-width: 576px) and (max-width: 768px) {}

// MD
@media only screen and (min-width: 769px) and (max-width: 992px) {}