.audio-player {
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #010a19d7;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 2px #969696;
    z-index: 3;

    .audio-player-wrapper {
        padding: 8px 15px;
        display: flex;
        align-items: center;

        .left {
            flex: 3.5;
            display: flex;
            align-items: center;
            min-width: 0;

            .img-wrapper {
                width: 60px;
                min-width: 60px;
                aspect-ratio: 1;
                border-radius: 10px;
                overflow: hidden;
                cursor: pointer;
                margin-right: 15px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .song-info {
                margin-right: 15px;
                min-width: 0;

                .name {
                    font-size: 13px;
                    font-weight: 500;
                    margin-bottom: 5px;
                    cursor: pointer;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .artists {
                    font-size: 12px;
                    font-weight: 400;
                    cursor: pointer;
                    transition: color 0.1s ease;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    span {
                        color: #cccccc;

                        &:not(.comma):hover {
                            color: white;
                        }
                    }
                }
            }

            .like-btn {
                font-size: 20px;
            }
        }

        .middle {
            flex: 5;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .control-button {
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: space-around;

                .icon {
                    font-size: 30px;
                    color: #969696;
                    transition: color 0.1s ease;
                }

                .replay-wrapper {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .replay-all {
                        position: absolute;
                        top: -5px;
                        right: -2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .replay-all-text {
                            font-size: 10px;
                            font-weight: 500;
                            color: #EFFE62;
                            cursor: default;
                        }
                    }
                }

                .replay,
                .shuffle {
                    font-size: 24px;
                }

                .play-pause {
                    .icon {
                        color: #EFFE62;
                        font-size: 42px;
                        transition: transform 0.1s ease;
                    }
                }

                .selected {
                    color: #EFFE62;
                    transition: opacity .1s ease;
                }
            }

            .control-timeline {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .time {
                    width: 32px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #cccccc;
                    cursor: default;

                    &.duration {
                        margin-left: 8px;
                    }
                }

                .slider {
                    width: 85%;

                    .MuiSlider-rail {
                        color: #969696;
                    }

                    .MuiSlider-track,
                    .MuiSlider-thumb {
                        color: #EFFE62;
                    }

                    .MuiSlider-thumb {
                        width: 0;
                        height: 0;
                        transition: width .1s ease, height .1s ease;

                        &.Mui-focusVisible {
                            box-shadow: none !important;
                        }
                    }

                    .MuiSlider-thumb.Mui-active {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }

        .right {
            flex: 3.5;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 5px;

            .icon {
                font-size: 24px;
                color: #969696;
                transition: color 0.1s ease;
                margin-right: 10px;

                &.lyrics {
                    font-size: 20px;
                }
            }

            .divider {
                border-color: #9696968a;
                margin-right: 22px;
            }

            .selected {
                color: #EFFE62;
                transition: opacity .1s ease;
            }

            .volume-wrapper {
                width: 30%;
                display: flex;
                align-items: center;

                .volume-control {
                    .MuiSlider-rail {
                        color: #969696;
                    }

                    .MuiSlider-track,
                    .MuiSlider-thumb {
                        color: white;
                    }

                    .MuiSlider-thumb {
                        width: 0;
                        height: 0;
                        transition: width .1s ease, height .1s ease;

                        &.Mui-focusVisible {
                            box-shadow: none !important;
                        }
                    }
                }
            }
        }
    }

    .queue-list {
        width: 350px;
        padding: 15px;
        background-color: #0B182E;
        border-radius: 10px 0 0 0;
        max-height: 230px;
        overflow-y: auto;

        .title {
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 10px;
        }

        .no-item-text {
            font-size: 13px;
            font-weight: 500;
            color: #cccccc;
            margin-bottom: 10px;
        }

        .wrapper-img {
            display: flex;
            justify-content: center;

            .no-item-img {
                width: 120px;
            }
        }

        .item-type-text {
            font-size: 13px;
            font-weight: 500;
            color: #cccccc;
            margin-bottom: 5px;
        }

        .divider {
            border-color: #9696968a;
            margin: 10px 0;
        }

        &::-webkit-scrollbar {
            width: 12px;
            background-color: #333333;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
            background-color: #969696a6;
        }
    }
}

// Thiet bi khong phai cam ung
@media (hover: hover) and (pointer: fine) {
    .audio-player {
        .audio-player-wrapper {
            .left {
                .song-info {
                    .name:hover {
                        a {
                            text-decoration: underline;
                        }
                    }

                    span:not(.comma):hover {
                        color: white;
                        text-decoration: underline;
                    }
                }
            }

            .middle {
                .control-button {
                    .icon {
                        &:not(.selected, .play, .pause):hover {
                            color: white;
                        }
                    }

                    .play-pause {
                        .icon {
                            &:hover {
                                transform: scale(1.1);
                            }
                        }
                    }

                    .selected {
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }

                .control-timeline {
                    .slider {
                        &:hover {
                            .MuiSlider-thumb {
                                width: 12px;
                                height: 12px;
                                box-shadow: none !important;
                            }
                        }
                    }
                }
            }

            .right {
                .icon {
                    &:not(.selected):hover {
                        color: white;
                    }
                }

                .selected {
                    &:hover {
                        opacity: 0.8;
                    }
                }

                .volume-wrapper {
                    &:hover {
                        .icon {
                            color: white;
                        }

                        .volume-control {
                            cursor: default;

                            .MuiSlider-track,
                            .MuiSlider-thumb {
                                color: #EFFE62;
                            }

                            .MuiSlider-thumb {
                                width: 12px;
                                height: 12px;
                                box-shadow: none !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

// XS
@media only screen and (max-width: 575px) {
    .audio-player {
        bottom: 70px;

        .audio-player-wrapper {
            .left {
                flex: 9.5;

                .img-wrapper {
                    width: 45px;
                    min-width: 45px;
                }

                .more {
                    display: none;
                }
            }

            .middle {
                flex: 1.5;

                .control-button {

                    .replay-wrapper,
                    .icon:not(.play, .pause) {
                        display: none;
                    }
                }

                .control-timeline {
                    z-index: 1;
                    position: absolute;
                    top: -20%;
                    left: 0;
                    display: block;

                    .time {
                        display: none;
                    }

                    .slider {
                        width: 100%;
                        padding: 3px 0;
                    }
                }
            }

            .right {
                flex: 1;
                padding-right: 0;

                .divider {
                    margin: 0 15px;
                }

                .icon {
                    margin-right: 0;

                    &.download,
                    &.lyrics {
                        display: none;
                    }
                }

                .volume-wrapper {
                    display: none;
                }
            }
        }

        .queue-list {
            width: 100vw;
            max-height: 300px;
        }
    }
}

// SM
@media only screen and (min-width: 576px) and (max-width: 768px) {
    .audio-player {
        .audio-player-wrapper {
            .left {
                flex: 4.5;
            }

            .middle {
                flex: 6;
            }

            .right {
                flex: 1.5;

                .icon {

                    &.download,
                    &.lyrics {
                        display: none;
                    }
                }

                .volume-wrapper {
                    display: none;
                }
            }
        }
    }
}

// MD
@media only screen and (min-width: 769px) and (max-width: 992px) {
    .audio-player {
        .audio-player-wrapper {
            .left {
                flex: 4.5;
            }

            .middle {
                flex: 6;
            }

            .right {
                flex: 1.5;

                .icon {

                    &.download,
                    &.lyrics {
                        display: none;
                    }
                }

                .volume-wrapper {
                    display: none;
                }
            }
        }
    }
}

// LG
@media only screen and (min-width: 993px) and (max-width: 1200px) {}

// XL
@media only screen and (min-width: 1201px) and (max-width: 1400px) {}

// XXL
@media only screen and (min-width: 1401px) {}