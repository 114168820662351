.wrapper-item {
    width: 20%;
    min-width: 20%;

    .item {
        margin-right: 20px;
        position: relative;

        .card {
            width: 100%;
            // aspect-ratio: 0.68;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
            box-shadow: 0 0 10px black;
            background: rgb(128 128 128 / 0.2);
            padding: 3px;
            position: relative;
            --x-px: calc(var(--x) * 1px);
            --y-px: calc(var(--y) * 1px);

            &::before,
            &::after {
                position: absolute;
                display: block;
                content: "";
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                inset: 0px;
                background: radial-gradient(550px circle at var(--x-px) var(--y-px),
                        rgba(255, 255, 255, 1),
                        transparent 30%);
                transition: opacity 0.4s ease;
                opacity: 0;
            }

            &::before {
                z-index: 1;
            }

            &::after {
                z-index: -1;
            }

            &.artist,
            &.profile {
                .inner {
                    .card-img {
                        padding: 10px;

                        img {
                            border-radius: 50%;
                        }
                    }
                }
            }

            .inner {
                height: 100%;
                min-height: 235px;
                background-color: rgba(26, 26, 26, 0.8);
                position: relative;
                border-radius: 10px;
                overflow: hidden;

                .card-button {
                    position: absolute;
                    right: 10px;
                    top: 45%;
                    z-index: 2;
                    opacity: 0;
                    transition: top .25s ease, scale .1s ease-in-out;

                    &.played {
                        top: 50%;
                        opacity: 1;
                    }
                }

                .card-img {
                    width: 100%;
                    aspect-ratio: 1;
                    overflow: hidden;
                    display: flex;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        box-shadow: 0 1px 3px #232323;
                    }

                    .wrapper-img {
                        flex: 1;
                        height: 50%;
                    }
                }

                .card-content {
                    padding: 10px;

                    .wrapper-card-content {
                        aspect-ratio: 2.5;

                        .name {
                            font-weight: 500;
                            font-size: 16px;
                            margin: 3px 0;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .content {
                            p {
                                line-height: 1.6;
                                font-size: 13px;
                                font-weight: 400;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                span {
                                    color: #cccccc;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Thiet bi khong phai cam ung
@media (hover: hover) and (pointer: fine) {
    .wrapper-item {
        .item {
            .card {
                &:hover {
                    .inner {
                        .card-button {
                            top: 50%;
                            opacity: 1;

                            &:hover {
                                scale: 1.1;
                            }
                        }
                    }

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

// XS
@media only screen and (max-width: 575px) {
    .wrapper-item {
        width: 48%;
        min-width: 48%;

        .item {
            .card {
                .inner {
                    .card-content {
                        .wrapper-card-content {
                            aspect-ratio: 2;
                        }
                    }
                }
            }
        }
    }
}

// SM
@media only screen and (min-width: 576px) and (max-width: 768px) {
    .wrapper-item {
        width: 33.33%;
        min-width: 33.33%;
    }
}

// MD
@media only screen and (min-width: 769px) and (max-width: 992px) {
    .wrapper-item {
        width: 33.33%;
        min-width: 33.33%;
    }
}

// LG
@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .wrapper-item {
        width: 25%;
        min-width: 25%;
    }
}

// XL
@media only screen and (min-width: 1201px) and (max-width: 1400px) {}

// XXL
@media only screen and (min-width: 1401px) {
    .wrapper-item {
        width: 16.67%;
        min-width: 16.67%;
    }
}