.home {

    .title {
        .title-text {
            font-weight: 600;
        }
    }

    .home-wrapper {
        padding: 5px 15px 90px;

        .top {
            display: flex;
            margin-bottom: 40px;

            .title {
                margin-bottom: 12px;
                display: flex;
                align-items: center;

                img {
                    width: 40px;
                }
            }

            .top-artists {
                flex: 6.5;
                margin-right: 25px;
                min-width: 480px;
            }

            .top-songs {
                flex: 5.5;
                min-width: 400px;

                .songs {
                    height: 300px;
                    overflow-y: hidden;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
        }
    }
}

// XS
@media only screen and (max-width: 575px) {
    .home {
        .home-wrapper {
            padding-bottom: 136px;

            .top {
                display: block;

                .top-artists {
                    min-width: auto;
                    width: 100%;
                    margin-bottom: 30px;
                }

                .top-songs {
                    min-width: auto;
                }
            }
        }
    }
}

// SM
@media only screen and (min-width: 576px) and (max-width: 768px) {
    .home {
        .home-wrapper {
            .top {
                display: block;
            }
        }
    }
}

// MD
@media only screen and (min-width: 769px) and (max-width: 992px) {
    .home {
        .home-wrapper {
            .top {
                display: block;
            }
        }
    }
}

// LG
@media only screen and (min-width: 993px) and (max-width: 1200px) {}

// XL
@media only screen and (min-width: 1201px) and (max-width: 1400px) {}

// XXL
@media only screen and (min-width: 1401px) {}