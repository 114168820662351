.more {
    .button {
        .more-icon {
            font-size: 22px;
            color: #969696;
            transition: color .1s ease;
        }

        &:hover {
            .more-icon {
                color: white;
            }
        }
    }

    .popper-list {
        z-index: 3333;

        .list {
            background-color: #333333;

            .list-item {
                color: white;
                font-size: 12px;
                font-weight: 500;
                font-family: inherit;
                padding: 8px 16px;

                &:hover,
                &:focus {
                    background-color: #96969650;
                }

                &.playlist,
                &.artist {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &.is-open {
                        background-color: #96969650;
                    }

                    .more-icon {
                        font-size: 18px;
                        transition: none;

                        &.arrow {
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }

    .divider {
        border-color: #96969650;
        margin: 0 !important;
    }

    .popper-artists {
        z-index: 3333;

        .artists {
            background-color: #414040;

            .artist-item {

                &:hover,
                &:focus {
                    background-color: #96969650;
                }

                color: white;
                font-size: 12px;
                font-weight: 500;
                font-family: 'Montserrat',
                sans-serif;
            }
        }

    }

    .popper-playlists {
        z-index: 3333;

        .playlists {
            background-color: #414040;
            width: 200px;

            .playlist-item {
                color: white;

                &:hover,
                &:focus {
                    background-color: #96969650;

                    .more-icon {
                        color: white;
                    }
                }
            }

            .more-top {
                display: flex;
                flex-direction: column;
                max-height: 200px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 6px;
                }

                &::-webkit-scrollbar-track {
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                    background-color: #96969686;
                }

                .no-playlist {
                    font-size: 13px;
                    font-weight: 400;
                    color: white;
                    padding: 10px 15px;
                }

                .playlist-item {
                    width: 100%;
                    font-family: inherit;
                    font-size: 13px;
                    font-weight: 500;

                    .name {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;

                        .text {
                            flex: 11;
                            overflow: hidden;
                            text-wrap: nowrap;
                            text-overflow: ellipsis;
                            margin-right: 15px;
                        }

                        .icon {
                            flex: 1;

                            .more-icon {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            .more-bottom {
                .divider {
                    margin: 8px 0;
                }

                .playlist-item {
                    font-size: 12px;
                    font-weight: 500;
                    font-family: inherit;
                    padding-left: 8px;
                    width: 100%;

                    .more-icon {
                        font-size: 22px;
                        margin-right: 5px;
                    }
                }

                .form-create-playlist {
                    padding: 10px;
                    display: none;

                    &.true {
                        display: block;
                    }

                    .form-group {
                        margin-bottom: 15px;

                        .form-label {
                            font-size: 12px;
                            font-weight: 500;
                            font-family: inherit;
                            color: white;
                        }

                        .form-input {
                            color: white;
                            font-size: 14px;
                            font-weight: 500;
                            font-family: inherit;

                            &::before {
                                border-bottom: 2px solid #cccccc;
                            }

                            &::after {
                                border-bottom: 2px solid #EFFE62;
                            }

                            &.type {
                                width: 100%;

                                svg {
                                    color: white;
                                }

                                option {
                                    background-color: #333333;
                                    font-size: 14px;
                                    font-weight: 500;
                                    padding: 5px;
                                }
                            }
                        }
                    }

                    .wrapper-button {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;

                        button {
                            background: none;
                            border: 2px solid white;
                            color: white;
                            font-size: 14px;
                            font-weight: 500;
                            padding: 6px 12px;
                            border-radius: 3px;
                            position: relative;
                            left: 0;
                            cursor: pointer;
                            transition: all .1s ease;

                            &:not(:disabled):hover {
                                border: 2px solid #EFFE62;
                                background-color: #EFFE62;
                                color: black;
                                font-weight: 700;
                            }

                            &:disabled {
                                color: #cccccc;
                                border-color: #969696;
                                cursor: default;
                            }
                        }
                    }
                }
            }
        }
    }
}