.artist-item {
    position: relative;

    .img-wrapper {
        width: 100%;
        height: 300px;
        border-radius: 10px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.88);
        }
    }

    .artist-info {
        position: absolute;
        bottom: 60px;
        left: 30px;

        .artist-name {
            font-weight: 700;
            font-size: 34px;

            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .artist-listeners {
            margin-top: 3px;
            font-weight: 500;
            font-size: 14px;
        }
    }

    .play-button {
        position: absolute;
        bottom: 10px;
        left: 30px;
    }

    .follow-button {
        position: absolute;
        bottom: 10px;
        left: 48%;
    }
}

// XS
@media only screen and (max-width: 575px) {
    .artist-item {
        .follow-button {
            left: 65%;
        }
    }
}