.reset-password {

    .title,
    .content,
    .form-control,
    button {
        font-family: inherit;
    }

    .title {
        font-weight: 800;
        font-size: 24px;
    }

    .content {
        font-size: 13px;
        font-weight: 500;
        color: inherit;
        margin-bottom: 32px;
    }

    .form-control {
        margin-bottom: 20px;
        width: 100%;
        font-size: 18px;
        display: flex;
        flex-direction: column;

        label {
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 8px;
        }

        input {
            border: none;
            border-bottom: 1px solid #969696;
            font-size: 14px;
            font-weight: 500;
            font-family: inherit;
            padding-bottom: 8px;
            transition: all .1s ease;

            &:focus {
                outline: none;
                border-bottom: 1px solid black;
            }
        }
    }

    .cancel-button,
    .password-button {
        outline: none;
        border: none;
        padding: 10px 0;
        border-radius: 30px;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 24px;
        width: 30%;
        transition: background-color .1s ease;

        &.cancel-button {
            &:not(:disabled):hover {
                background-color: darken(#F0F0F0, 10%);
            }
        }

        &.password-button {
            background-color: #EFFE62;

            &:not(:disabled):hover {
                background-color: darken(#EFFE62, 10%);
            }
        }

        &:disabled {
            background-color: #969696;
            cursor: default;
        }

        &:focus {
            outline: none;
        }

        &:not(:disabled):active {
            scale: 0.9;
        }
    }
}

.login {
    padding: 10px 15px 110px;

    .login-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .login-container {
            background-color: #fff;
            border-radius: 20px;
            overflow: hidden;
            width: 65vw;
            max-width: 100%;
            height: 450px;
            box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25);
            position: relative;

            &.right-panel-active {
                .login-form {
                    transform: translateX(100%);
                }

                .register-form {
                    transform: translateX(100%);
                    opacity: 1;
                    z-index: 2;
                }

                .overlay-container {
                    transform: translateX(-100%);

                    .overlay {
                        transform: translateX(50%);

                        .overlay-left {
                            transform: translateX(0);
                        }

                        .overlay-right {
                            transform: translateX(20%);
                        }
                    }
                }
            }

            .login-form,
            .register-form {
                position: absolute;
                top: 0;
                left: 0;
                width: 50%;
                height: 100%;
                transition: all 0.6s ease-in-out;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .register-form {
                z-index: 1;
                opacity: 0;
            }

            .login-form {
                z-index: 2;
                flex-direction: column;
            }

            form {
                height: 100%;
                width: 100%;
                background-color: #fff;
                color: black;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 25px 40px;

                .title {
                    font-weight: 800;
                    font-size: 24px;
                    margin-bottom: 15px;
                }

                .form-control {
                    margin-bottom: 20px;
                    width: 100%;
                    font-size: 18px;
                    display: flex;
                    flex-direction: column;

                    label {
                        font-size: 13px;
                        font-weight: 600;
                        margin-bottom: 8px;
                    }

                    input {
                        border: none;
                        border-bottom: 1px solid #969696;
                        font-size: 14px;
                        font-weight: 500;
                        font-family: inherit;
                        padding-bottom: 8px;
                        transition: all .1s ease;

                        &:focus {
                            outline: none;
                            border-bottom: 1px solid black;
                        }
                    }

                    .wrapper-password {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        position: relative;

                        .password,
                        .password-signin {
                            width: 100%;
                            padding-right: 30px
                        }

                        .wrapper-icon {
                            position: absolute;
                            right: 0;
                            cursor: pointer;
                        }
                    }

                    .note {
                        margin-top: 5px;
                        font-size: 13px;
                        font-weight: 500;
                        color: #f26725;
                    }
                }

                .forget-pass {
                    display: block;
                    width: 100%;
                    text-align: right;
                    font-size: 13px;
                    font-weight: 500;
                    cursor: pointer;
                    margin-bottom: 10px;

                    &:hover {
                        text-decoration: underline;
                    }

                    &.true {
                        margin-top: 15px;
                    }
                }

                button:not(.close-button) {
                    margin-bottom: 30px;
                    outline: none;
                    border: none;
                    background-color: #EFFE62;
                    padding: 10px 0;
                    width: 75%;
                    border-radius: 30px;
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: uppercase;
                    cursor: pointer;

                    &.signin-button {
                        &.true {
                            margin-top: 15px;
                        }
                    }

                    &:disabled {
                        background-color: #969696;
                        cursor: default;
                    }

                    &:focus {
                        outline: none;
                    }

                    &:not(:disabled):active {
                        scale: 0.9;
                    }

                    &:not(:disabled):hover {
                        background-color: darken(#EFFE62, 10%);
                    }
                }

            }

            .overlay-container {
                position: absolute;
                top: 0;
                left: 50%;
                width: 50%;
                height: 100%;
                overflow: hidden;
                transition: transform 0.6s ease-in-out;
                z-index: 3;

                .overlay {
                    position: relative;
                    color: #fff;
                    background-image: url("../../images/loginBackground.png");
                    background-size: cover;
                    height: 100%;
                    left: -100%;
                    width: 200%;
                    transform: translateX(0);
                    transition: transform 0.6s ease-in-out;

                    .overlay-left,
                    .overlay-right {
                        position: absolute;
                        top: 0;
                        width: 50%;
                        height: 100%;
                        transform: translateX(0);
                        transition: transform 0.6s ease-in-out;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        padding: 25px 40px;

                        .title {
                            font-size: 32px;
                            font-weight: 700;
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 1.4;
                            margin-bottom: 30px;
                        }

                        button {
                            border: none;
                            background: none;
                            background-color: #ffffff29;
                            border: 3px solid white;
                            padding: 10px 0;
                            width: 75%;
                            border-radius: 30px;
                            font-size: 14px;
                            font-weight: 600;
                            color: white;
                            text-transform: uppercase;
                            cursor: pointer;

                            &:hover {
                                background-color: darken(#ffffff29, 20%);
                            }

                            &:active {
                                scale: 0.9;
                            }
                        }
                    }

                    .overlay-left {
                        transform: translateX(-20%);
                    }

                    .overlay-right {
                        right: 0;
                        transform: translateX(0);
                    }
                }
            }
        }
    }
}

// XS
@media only screen and (max-width: 575px) {
    .login {
        padding-bottom: 156px;

        .login-wrapper {
            .login-container {
                width: 100vw;
                height: 90vh;

                &.right-panel-active {
                    .login-form {
                        transform: translateY(100%);
                    }

                    .register-form {
                        transform: translateY(100%);
                        opacity: 1;
                        z-index: 2;
                    }

                    .overlay-container {
                        transform: translateY(-100%);

                        .overlay {
                            transform: translateY(50%);

                            .overlay-left {
                                transform: translateY(0);
                            }

                            .overlay-right {
                                transform: translateY(100%);
                            }
                        }
                    }
                }

                .login-form,
                .register-form {
                    width: 100%;
                    height: 50%;
                }

                .overlay-container {
                    top: 50%;
                    left: 0;
                    width: 100%;
                    height: 50%;

                    .overlay {
                        height: 200%;
                        top: -100%;
                        left: 0;
                        width: 100%;

                        .overlay-left,
                        .overlay-right {
                            width: 100%;
                            height: 50%;
                        }

                        .overlay-left {
                            transform: translateY(-50%);
                        }

                        .overlay-right {
                            transform: translateY(100%);
                        }
                    }
                }
            }
        }
    }

}

// SM & MD
@media only screen and (min-width: 576px) and (max-width: 992px) {
    .login {
        .login-wrapper {
            .login-container {
                width: 100vw;
            }
        }
    }
}