.navbar {
    z-index: 6;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;

    &.scrolled {
        background-color: #010A19;
        box-shadow: 0px 7px 10px 5px #010A19;
    }

    .navbar-wrapper {
        display: flex;
        align-items: center;
        padding: 15px;

        .left {
            flex: 6;
            display: flex;

            .wrapper-left {
                min-width: 295px;
                display: flex;
                align-items: center;
                background-color: #0B182E;
                height: 30px;
                width: 70%;
                padding: 20px 10px;
                border-radius: 30px;

                .icon {
                    color: #cccccc;
                    margin-right: 3px;
                }

                .search-input {
                    color: white;
                    font-family: inherit;
                    font-size: 13px;
                    font-weight: 500;
                    background-color: transparent;
                    border: none;
                    width: 100%;

                    &:focus {
                        outline: none;
                    }
                }

                &:focus-within {
                    outline: 1.5px solid white;

                    .icon {
                        color: white;
                    }
                }
            }
        }

        .right {
            flex: 6;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .logIn-btn {
                padding: 10px 20px;
                border-radius: 30px;
                font-size: 13px;
                font-weight: 700;
                color: white;
                text-transform: uppercase;
                position: relative;
                overflow: hidden;
                transition: color .3s;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;
                    z-index: -2;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    width: 5px;
                    height: 5px;
                    background-color: #EFFE62;
                    transition: all .3s;
                    border-radius: 50%;
                    z-index: -1;
                }

                &:hover {
                    color: #010A19;

                    &::before {
                        top: 0;
                        right: 0;
                        border-radius: 30px;
                        width: 100%;
                        height: 100%;
                    }
                }

                &.true {
                    color: #010A19;

                    &::after {
                        background-color: #EFFE62;
                    }
                }
            }

            .avatar {
                width: 32px;
                height: 32px;
                cursor: pointer;
                transition: opacity .1s ease;
                margin-right: 10px;
                border-radius: 50%;
                object-fit: cover;

                &:hover {
                    opacity: 0.8;
                }
            }

            .user-info {
                .username {
                    font-size: 13px;
                    font-weight: 500;
                }

                .email {
                    font-size: 11px;
                    font-weight: 500;
                    color: #EFFE62;
                }
            }

            .list {
                background-color: #333333;
                color: white;
                margin-top: 10px;

                .list-item {
                    font-size: 12px;
                    font-weight: 500;
                    font-family: inherit;
                    padding: 8px 16px;

                    &:hover,
                    &:focus {
                        background-color: #96969650;
                    }

                    &.premium {
                        position: relative;
                    }
                }

                .divider {
                    border-color: #96969650;
                }
            }
        }
    }
}

// Thiet bi khong phai cam ung
@media (hover: hover) and (pointer: fine) {
    .navbar {
        .navbar-wrapper {
            .left {
                .wrapper-left {
                    &:hover {
                        box-shadow: inset 0 0 2px #cccccc;
                        background-color: #0f2242;

                        .icon {
                            color: white;
                        }
                    }
                }
            }
        }
    }
}

// XS
@media only screen and (max-width: 575px) {
    .navbar {
        .navbar-wrapper {
            .left {
                flex: 7.5;

                .wrapper-left {
                    width: 100%;
                    min-width: 100px;
                }
            }

            .right {
                flex: 4.5;

                .avatar {
                    margin-right: 0;
                }

                .user-info {
                    display: none;
                }
            }
        }
    }
}

// SM
@media only screen and (min-width: 576px) and (max-width: 768px) {}

// MD
@media only screen and (min-width: 769px) and (max-width: 992px) {}

// LG
@media only screen and (min-width: 993px) and (max-width: 1200px) {}

// XL
@media only screen and (min-width: 1201px) and (max-width: 1400px) {}

// XXL
@media only screen and (min-width: 1401px) {}