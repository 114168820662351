.songItem {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-radius: 6px;

    &.played {
        .left {
            .songItem-info {
                .name {
                    color: #EFFE62;
                }
            }
        }
    }

    .left {
        flex: 7.5;
        display: flex;
        align-items: center;
        min-width: 0;

        .songItem-img {
            width: 44px;
            min-width: 44px;
            height: 100%;
            aspect-ratio: 1;
            margin-right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .button {
                position: absolute;
                color: white;

                .icon {
                    transition: transform .1s ease;
                    opacity: 0;

                    &.play {
                        font-size: 32px;
                    }

                    &.pause {
                        font-size: 28px;
                    }

                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
        }

        .songItem-info {
            min-width: 0;

            .name {
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 5px;
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .artists {
                font-size: 12px;
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                transition: color .1s ease;

                span {
                    cursor: pointer;
                    color: #cccccc;
                }
            }
        }
    }

    .right {
        flex: 4.5;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .like-btn {
            flex: 5;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
        }

        .listeners {
            flex: 4;
            display: flex;
            align-items: center;
            transition: color .1s ease;
            margin-right: 5px;

            .text {
                font-size: 14px;
                font-weight: 500;
                color: #cccccc;
                margin-right: 5px;
                cursor: default;
            }

            .icon {
                font-size: 16px;
                color: #969696;
            }
        }

        .more {
            flex: 3;
        }
    }
}

// Thiet bi khong phai cam ung
@media (hover: hover) and (pointer: fine) {
    .songItem {

        &:focus,
        &:focus-within,
        &:hover {
            .left {
                .songItem-img {
                    img {
                        filter: brightness(0.6);
                    }

                    .button {
                        .icon {
                            opacity: 1;
                        }
                    }

                    .music-wave {
                        opacity: 0;
                    }
                }

                .songItem-info {
                    .artists {
                        span {
                            &:not(.comma) {
                                color: white;
                            }
                        }
                    }
                }
            }

            .right {
                .like-btn {
                    .icon {
                        &.liked {
                            opacity: 1;
                        }
                    }
                }

                .listeners {

                    .text,
                    .icon {
                        color: white;
                    }
                }

                .more {
                    .button {
                        .icon {
                            color: white;
                        }
                    }
                }
            }
        }

        &:focus,
        &:focus-within {
            background-color: #9696968a;
        }

        &:not(:focus, :focus-within):hover {
            background-color: #38393ba8;
        }

        .left {
            .songItem-info {
                .name {
                    &:hover {
                        text-decoration: underline;
                    }
                }

                .artists {
                    span {
                        &:not(.comma):hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .right {
            .like-btn {
                .icon {
                    &.liked {
                        opacity: 0;
                    }
                }
            }
        }
    }
}

// XS
@media only screen and (max-width: 575px) {
    .songItem {
        .left {
            flex: 9;
            margin-right: 20px;
        }

        .right {
            flex: 3;
            justify-content: flex-end;

            .like-btn {
                display: none;
            }
        }
    }
}