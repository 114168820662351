.show-more {
    .show-more-wrapper {
        padding: 5px 15px 90px;

        .section-name {
            margin-bottom: 20px;
            font-weight: 600;
        }

        .cards {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .wrapper-item {
                .item {
                    padding-bottom: 40px;
                }
            }

        }
    }
}

// XS
@media only screen and (max-width: 575px) {
    .show-more {
        .show-more-wrapper {
            padding-bottom: 136px;

            .cards {
                justify-content: space-between;

                .wrapper-item {
                    .item {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}