.play-button {
    button {
        display: flex;
        align-items: center;
        border: none;
        background-color: #333333a4;
        color: #cccccc;
        font-size: 14px;
        font-weight: 600;
        font-family: inherit;
        padding: 8px 15px;
        border-radius: 10px;
        transition: all .1s ease;
        cursor: pointer;

        .icon {
            margin-right: 5px;
        }

        &.played {
            color: #EFFE62;
        }

        &:not(.played):hover {
            color: white;
            background-color: #3f3f3fb3;
            box-shadow: inset 1px 2px 4px #333333;
        }
    }
}