.album {
    .album-wrapper {
        padding: 5px 15px 90px;

        .top-album {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                background-image: var(--background-src);
                background-repeat: no-repeat;
                background-size: cover;
                opacity: 0.5;
                filter: blur(30px);
                z-index: -1;
                width: 100%;
                height: 320px;
            }

            .left {
                flex: 5;

                .wrapper-left {
                    display: flex;
                    align-items: center;
                    position: relative;
                    margin-right: 140px;

                    .album-img {
                        width: 250px;
                        height: 250px;
                        overflow: hidden;
                        border-radius: 10px;
                        box-shadow: 0 3px 10px black;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .disk {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 0 0 10px black;
                        z-index: -1;

                        .outside {
                            position: absolute;
                            width: 200px;
                            height: 200px;
                            background: var(--color);
                            background-image: linear-gradient(to right, rgb(255, 255, 255, 0.5), var(--color-second));
                            background-blend-mode: multiply;
                            border-radius: 50%;

                            &.played {
                                animation: rotate 3s linear infinite;
                            }

                            @keyframes rotate {
                                from {
                                    transform: rotate(360deg);
                                }

                                to {
                                    transform: rotate(0deg);
                                }
                            }
                        }

                        .inner {
                            position: absolute;
                            background-color: #2c2c2ccc;
                            width: 25%;
                            height: 25%;
                            border-radius: 50%;
                        }
                    }
                }
            }

            .right {
                position: relative;
                flex: 7;

                .type {
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 5px;
                }

                .album-name {
                    font-size: 32px;
                    font-weight: 700;
                    margin-bottom: 15px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .owner {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;

                    .owner-img {
                        width: 34px;
                        height: 34px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 8px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .owner-name {
                        font-weight: 600;
                        font-size: 14px;
                        cursor: default;

                        span {
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .album-summary {
                    font-weight: 500;
                    font-size: 13px;
                    color: #cccccc;

                    b {
                        color: white;
                    }
                }
            }
        }

        .bottom-album {
            .buttons {
                padding: 20px 0;
                display: flex;
                align-items: center;

                img {
                    width: 55px;
                    transition: scale .1s ease;
                    cursor: pointer;
                    margin-right: 10px;

                    &:hover {
                        scale: 1.1;
                    }
                }

                .icon {
                    font-size: 36px;
                    color: #969696;
                    cursor: pointer;
                    transition: color .1s ease;

                    &:hover {
                        color: white;
                    }

                    &.unliked {
                        color: #EFFE62;
                    }
                }

                .more-icon {
                    font-size: 36px;
                }
            }

            .songs {
                margin-bottom: 30px;
            }
        }
    }
}

// XS
@media only screen and (max-width: 575px) {
    .album {
        .album-wrapper {
            padding-bottom: 110px;

            .top-album {
                display: block;
                margin-bottom: 0;

                .left {
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                }

                .right {
                    .album-summary {
                        .total-duration {
                            display: none;
                        }
                    }
                }
            }

            .bottom-album {
                .buttons {
                    img {
                        width: 48px;
                    }

                    .icon,
                    .more-icon {
                        font-size: 30px;
                    }
                }
            }
        }
    }
}