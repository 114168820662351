.queue-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 8px;
    border-radius: 3px;

    .left {
        flex: 1;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .number {
            font-size: 14px;
            font-weight: 400;
            color: #cccccc;
            cursor: default;
        }

        .icon {
            position: absolute;
            opacity: 0;
        }

        .play {
            font-size: 24px;
        }

        .pause {
            font-size: 22px;
        }
    }

    .middle {
        flex: 8.5;
        min-width: 0;
        display: flex;
        align-items: center;

        .img-wrapper {
            width: 32px;
            min-width: 32px;
            height: 100%;
            aspect-ratio: 1;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .song-info {
            min-width: 0;
            margin-right: 15px;

            .name {
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 3px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .artists {
                font-size: 10px;
                font-weight: 400;
                color: #cccccc;
                transition: color 0.1s ease;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                span {
                    cursor: pointer;
                }
            }
        }
    }

    .right {
        flex: 2.5;
        min-width: 0;
        display: flex;
        align-items: center;

        .first {
            flex: 6;
            min-width: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-right: 8px;

            .text {
                font-size: 11px;
                font-weight: 400;
                color: #cccccc;
                cursor: default;
            }

            .like-btn {
                font-size: 18px;
                position: absolute;

                .wrapper-like-btn {
                    opacity: 0;
                }
            }
        }

        .second {
            flex: 6;
            min-width: 0;
            display: flex;
            align-items: center;
            position: relative;

            .music-wave {
                .loader {
                    height: 13px;

                    .stroke {
                        width: 4px;
                    }
                }
            }
        }

        .third {
            display: flex;
            align-items: center;
        }
    }

    .icon {
        font-size: 18px;
        color: #969696;
        transition: color .1s ease;
    }
}

// Thiet bi khong phai cam ung
@media (hover: hover) and (pointer: fine) {
    .queue-item {
        &:hover {
            background-color: #96969650;

            .left {
                .number {
                    opacity: 0;
                }

                .icon {
                    opacity: 1;
                }
            }

            .right {
                .text {
                    opacity: 0;
                }

                .like-btn {
                    .wrapper-like-btn {
                        opacity: 1;
                    }
                }
            }
        }

        .icon {
            &:hover {
                color: white;
            }
        }

        .middle {
            .song-info {
                .name {
                    a {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .artists {
                    span {
                        &:not(.comma):hover {
                            color: white;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

}