.song-row {
    display: flex;
    align-items: center;
    padding: 5px 5px;
    border-radius: 3px;

    .number {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .text {
            font-size: 14px;
            font-weight: 500;
            color: #cccccc;
        }

        .icon {
            position: absolute;
            opacity: 0;
            transition: scale .1s ease;

            &.play {
                font-size: 26px;
            }

            &:hover {
                scale: 1.1;
            }
        }
    }

    .song-info {
        display: flex;
        align-items: center;
        min-width: 0;

        .wrapper-img {
            min-width: 44px;
            width: 44px;
            height: 100%;
            aspect-ratio: 1;
            margin-right: 15px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .wrapper-text {
            min-width: 0;

            .name {
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 5px;
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.played {
                    color: #EFFE62;
                }
            }

            .artists {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                span {
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: 500;
                    color: #cccccc;
                }
            }
        }
    }

    .song-album {
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .text {
            font-size: 13px;
            font-weight: 500;
            color: #cccccc;
            cursor: pointer;
        }
    }

    .song-createdAt {
        font-size: 13px;
        font-weight: 500;
        color: #cccccc;
    }

    .song-plays {
        font-size: 14px;
        font-weight: 500;
        color: #cccccc;
    }

    .song-duration {
        font-size: 14px;
        font-weight: 500;
        color: #cccccc;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            font-size: 20px;
            cursor: pointer;
        }
    }

    .like-btn {
        font-size: 20px;
        margin-right: 20px;
    }
}

// Thiet bi khong phai cam ung
@media (hover: hover) and (pointer: fine) {
    .song-row {

        &:focus,
        &:focus-within,
        &:hover {
            background-color: #96969650;

            .number {

                .text,
                .music-wave {
                    opacity: 0;
                }

                .icon {
                    opacity: 1;
                }
            }

            .song-info {
                .wrapper-text {
                    .artists {
                        span:not(.comma) {
                            color: white;
                        }
                    }
                }
            }

            .song-album,
            .song-plays {
                .text {
                    color: white;
                }
            }

            .button {
                &.like {
                    .like-btn {
                        .wrapper-like-btn {
                            .icon:not(.unliked) {
                                opacity: 1;
                            }
                        }
                    }
                }

                &.more {
                    .button {
                        opacity: 1;

                        .icon {
                            color: white;
                        }
                    }
                }
            }
        }

        &:focus,
        &:focus-within {
            background-color: #9696968a;
        }

        .song-info {
            .wrapper-text {
                .name {
                    &:hover {
                        text-decoration: underline;
                    }
                }

                .artists {
                    span {
                        &:not(.comma):hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .song-album {
            .text {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .button {
            &.like {
                .like-btn {
                    .wrapper-like-btn {
                        .icon:not(.unliked) {
                            opacity: 0;
                        }
                    }
                }
            }

            &.more {
                .button {
                    opacity: 0;
                }
            }
        }
    }
}

// XS
@media only screen and (max-width: 575px) {}

// SM
@media only screen and (min-width: 576px) and (max-width: 768px) {}

// MD
@media only screen and (min-width: 769px) and (max-width: 992px) {}